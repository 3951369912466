// PlyrPlayer.js

import Plyr from "plyr";
import { $ } from "./Utils.js";
import "plyr/dist/plyr.css";

export default class PlyrPlayer {
  constructor(playerSelector, videoId, options = {}) {
    this.playerSelector = playerSelector;
    this.options = options;
    this.videoId = videoId;
    this.playerElement = null;
    this.player = null;
    this.playerReadyPromise = null;
    this.hasInteracted = false;
    this.fadeDuration = 3000; // Fade duration in milliseconds
    this.fadeSteps = 145; // Number of steps for fading
    this.lastVolume = 0; // Store last volume level
    this.isFading = false;
    this.isProgrammaticPlay = false;
    this.isProgrammaticPause = false;

    this.initializePlayer();
  }

  initializePlayer() {
    this.playerElement = $(this.playerSelector);
    if (!this.playerElement) {
      console.error(
        `Element with selector '${this.playerSelector}' not found.`,
      );
      return;
    }

    // Initialize the Plyr player with custom options

    this.playerElement.setAttribute("data-plyr-provider", "vimeo");
    this.playerElement.setAttribute("data-plyr-embed-id", this.videoId);

    this.player = new Plyr(this.playerSelector, this.options);

    // Create a Promise that resolves when the player is ready
    this.playerReadyPromise = new Promise((resolve, reject) => {
      this.player.on("ready", () => {
        this.setupControlsInteractivity();
        this.setupPlayPauseListeners();
        resolve();
      });

      // Handle errors
      this.player.on("error", (event) => {
        console.error("Failed to initialize Plyr player:", event);
        reject(event);
      });
    });
  }

  setupControlsInteractivity() {
    const controlsElement = this.playerElement.querySelector(".plyr__controls");

    if (!controlsElement) return; // Ensure element exists

    // Listen for click to mark that interaction occurred
    controlsElement.addEventListener("click", () => {
      this.hasInteracted = true;
    });

    // Apply .interacted class on mouseleave if interaction occurred
    controlsElement.addEventListener("mouseleave", () => {
      if (this.hasInteracted) {
        controlsElement.classList.add("interacted");
      }
    });
  }

  setupPlayPauseListeners() {
    this.player.on("play", () => {
      if (this.isProgrammaticPlay) {
        this.isProgrammaticPlay = false; // Reset the flag
      } else {
        // User-initiated play, start fade-in
        if (!this.isFading) {
          this.fadeVolume("in");
        }
      }
    });

    this.player.on("pause", () => {
      if (this.isProgrammaticPause) {
        this.isProgrammaticPause = false;
      } else {
        if (this.player.playing && !this.isFading) {
          this.fadeVolume("out");
        }
      }
    });
  }

  /**
   * Fades the audio in or out over the set duration.
   * @param {string} direction - 'in' to fade in, 'out' to fade out
   */
  fadeVolume(direction) {
    if (this.isFading) {
      return;
    }
    this.isFading = true;

    let initialVolume, targetVolume;

    if (direction === "in") {
      initialVolume = 0;
      targetVolume = this.lastVolume || 0; // Use last volume or default to 1
    } else {
      initialVolume = this.player.volume;
      targetVolume = 0;
      // Store the current volume before fading out
      this.lastVolume = this.player.volume;
    }

    const volumeStep = (targetVolume - initialVolume) / this.fadeSteps;
    let currentStep = 0;

    // Set initial volume immediately
    this.player.volume = initialVolume;

    const fade = () => {
      currentStep += 1;
      const newVolume = initialVolume + volumeStep * currentStep;
      this.player.volume = Math.min(Math.max(newVolume, 0), 1); // Ensure volume is between 0 and 1

      if (currentStep < this.fadeSteps) {
        requestAnimationFrame(fade);
      } else {
        // Ensure volume reaches the target at the end
        this.player.volume = targetVolume;

        this.isFading = false;

        if (direction === "out") {
          // Only pause when fade-out is complete
          this.isProgrammaticPause = true;
          this.player.pause();
        }
      }
    };

    fade();
  }

  /**
   * Returns a Promise that resolves when the player is ready.
   * @returns {Promise<void>} A promise that resolves when the player is ready.
   */
  ready() {
    if (this.playerReadyPromise) {
      return this.playerReadyPromise;
    } else {
      return Promise.reject(new Error("Player not initialized"));
    }
  }

  /**
   * Plays the video.
   * @returns {Promise<void>} A promise that resolves when the video starts playing.
   */
  async play() {
    if (!this.player) {
      console.error("Plyr player is not initialized.");
      return Promise.reject(new Error("Player not initialized"));
    }

    return this.ready().then(() => {
      // Set initial volume to 0
      this.player.volume = 0;
      // Start playing
      this.isProgrammaticPlay = true;
      return this.player
        .play()
        .then(() => {
          // Start fading in
          this.fadeVolume("in");
        })
        .catch((error) => {
          console.error("Failed to play video:", error);
          throw error;
        });
    });
  }

  /**
   * Pauses the video with volume fade-out.
   * @returns {Promise<void>} A promise that resolves when the video is paused.
   */
  async pause() {
    if (!this.player) {
      console.error("Plyr player is not initialized.");
      return Promise.reject(new Error("Player not initialized"));
    }

    // Start fading out
    this.fadeVolume("out");
    return Promise.resolve();
  }

  /**
   * Destroys the Plyr player instance and cleans up resources.
   * @returns {Promise<void>} A promise that resolves when the player is destroyed.
   */
  destroy() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
      this.playerElement = null;
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  }
}
