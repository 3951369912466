// home.js
import { Config } from "./WASP/Config.js";
import AnimLogo from "./WASP/AnimLogo.js";
import Clock from "./WASP/Clock.js";
import Hack from "./WASP/Hack.js";
import { $, isMobilePhone } from "./WASP/Utils.js";
import PlyrPlayer from "./WASP/PlyrPlayer.js";

let spline,
  animatedLogo,
  clock,
  hackedTextEffect,
  waspPlayers = {};

async function loadContentBasedOnDevice() {
  if (isMobilePhone()) {
    if (document.getElementById("desktop")) {
      const desktopContainer = $("#desktop");
      desktopContainer.classList.add("hidden");
      desktopContainer.setAttribute("aria-hidden", "true");
    }
    if (document.getElementById("home-reel")) {
      $("#home-reel").innerHTML = `
        <iframe
            src="https://player.vimeo.com/video/1027903275?autoplay=1&background=1&muted=1&controls=0&dnt=1"
            frameborder="0"
            loading="lazy"
            title="Wasp Studio background reel"
            aria-label="Wasp Silent Reel"
            width="1920"
            height="1080"
            allow="autoplay;"
        ></iframe>`;
    }
  } else {
    if (document.getElementById("vignette-mobile")) {
      $("#vignette-mobile").classList.add("hidden");
    }
    if (document.getElementById("desktop")) {
      $("#desktop").innerHTML = `
        <canvas id="canvas3d"></canvas>
          <div id="video-window">
            <div class="pixels" aria-hidden="true"></div>
            <div id="wasp-reel"></div>
            <div class="video-vignette" aria-hidden="true"></div>
          </div>`;

      waspPlayers.wasp = new PlyrPlayer(
        Config.SELECTORS.waspPlayer,
        Config.VIMEO_IDS.waspVideoId,
        Config.PLYR_OPTIONS.home,
      );

      await waspPlayers.wasp.ready().then(async () => {
        const Spline = await import("./WASP/Spline.js");
        spline = new Spline.default(
          Config.SELECTORS.canvas,
          Config.URLS.spline,
          animatedLogo,
        );
        await waspPlayers.wasp.play().then(() => {
          spline.revealSpline().then(() => {});
        });
      });
    }
    clock = new Clock(Config.SELECTORS.clock);
    $(Config.SELECTORS.year).textContent = new Date().getFullYear().toString();
  }
}

document.addEventListener("DOMContentLoaded", async () => {
  animatedLogo = new AnimLogo();
  animatedLogo.start();
  hackedTextEffect = new Hack(Config.SELECTORS.hackedText);
  hackedTextEffect.init();
  await loadContentBasedOnDevice();
  if (document.querySelector(Config.SELECTORS.fader))
    $(Config.SELECTORS.fader).classList.add("faded");
  if (document.querySelector(Config.SELECTORS.menu))
    $(Config.SELECTORS.menu).classList.add("revealed");
  if (!isMobilePhone()) animatedLogo.stop();
});

$(Config.SELECTORS.burger).addEventListener("click", () => {
  $(Config.SELECTORS.burger).classList.toggle("is-active");
  $(Config.SELECTORS.menu).classList.toggle("is-active");
});

async function navigateWithTransition(url) {
  if (document.querySelector(Config.SELECTORS.menu))
    $(Config.SELECTORS.menu).classList.remove("is-active");
  if (waspPlayers.wasp) waspPlayers.wasp.pause().then();
  if (spline) {
    await spline.hideSpline().then(() => {
      if (document.startViewTransition) {
        document.startViewTransition(() => {
          window.location.href = url;
        });
      } else {
        window.location.href = url;
      }
    });
  } else {
    setTimeout(() => {
      if (document.startViewTransition) {
        document.startViewTransition(() => {
          window.location.href = url;
        });
      } else {
        window.location.href = url;
      }
    }, 1000);
  }
}

document.body.addEventListener("click", async (event) => {
  const link = event.target.closest("a[data-link]");
  if (link) {
    event.preventDefault();
    const url = link.getAttribute("href");
    await navigateWithTransition(url);
  }
});
